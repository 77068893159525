// .storybook/manager.js

import { addons } from '@storybook/manager-api'
//import { themes } from '@storybook/theming'
import rocketThemeLight from './rocket-theme-light'

// https://storybook.js.org/docs/vue/configure/theming
// theme for the main UI
addons.setConfig({
  //  theme: themes.light,
  theme: rocketThemeLight,
})

// ISSUE DARK MODE
// https://github.com/hipstersmoothie/storybook-dark-mode/issues/231
// Some data is saved in the Local Storage. I deleted that by right-clicking on
// some blank space > navigating to the Application tab > expanding the Local Storage tab and deleting it.
